import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo_mini.png'


const _hoisted_1 = { class: "navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none" }
const _hoisted_2 = { class: "container-fluid bg-soft" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DashboardSideBar = _resolveComponent("DashboardSideBar")!
  const _component_DashboardTopNav = _resolveComponent("DashboardTopNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DashboardFooter = _resolveComponent("DashboardFooter")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_component_router_link, {
        class: "navbar-brand mr-lg-5",
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            class: "navbar-brand-dark",
            src: _imports_0,
            alt: "Volt logo"
          }, null, -1)
        ])),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
        _createElementVNode("button", {
          class: "navbar-toggler d-md-none collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#sidebarMenu",
          "aria-controls": "sidebarMenu",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ])
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DashboardSideBar),
          _createElementVNode("main", _hoisted_5, [
            _createVNode(_component_DashboardTopNav),
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                _createVNode(_Transition, {
                  name: "component-fade",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_KeepAlive, {
                      key: _ctx.$route.fullPath
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ], 1024))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_component_DashboardFooter)
          ])
        ])
      ])
    ])
  ]))
}