import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar navbar-top navbar-expand navbar-dashboard navbar-dark pl-0 pr-2 pb-0" }
const _hoisted_2 = { class: "container-fluid px-0" }
const _hoisted_3 = {
  class: "d-flex justify-content-between w-100",
  id: "navbarSupportedContent"
}
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "navbar-nav align-items-center" }
const _hoisted_6 = { class: "nav-item dropdown dropstart" }
const _hoisted_7 = { class: "dropdown-menu dropdown-menu-start mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Back = _resolveComponent("Back")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Back),
          _createElementVNode("form", {
            class: "navbar-search form-inline",
            id: "navbar-search-main",
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "input-group input-group-merge search-bar" }, [
              _createElementVNode("span", {
                class: "input-group-text",
                id: "topbar-addon"
              }, [
                _createElementVNode("span", { class: "fas fa-search" })
              ]),
              _createElementVNode("input", {
                type: "text",
                class: "form-control px-1",
                id: "topbarInputIconLeft",
                placeholder: "Search",
                "aria-label": "Search",
                "aria-describedby": "topbar-addon"
              })
            ], -1)
          ]), 32)
        ]),
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("a", {
              class: "nav-link pt-1 px-0",
              href: "#",
              role: "button",
              "data-bs-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false"
            }, [
              _createElementVNode("div", { class: "media d-flex align-items-center" }, [
                _createElementVNode("span", { class: "fas fa-user fa-2x text-black" })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "dropdown-item font-weight-bold",
                to: "/profile"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "far fa-user-circle" }, null, -1),
                  _createTextVNode(" My Profile ")
                ])),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createElementVNode("div", {
                role: "separator",
                class: "dropdown-divider"
              }, null, -1)),
              _createElementVNode("a", {
                class: "dropdown-item font-weight-bold",
                href: "javascript:void(0)",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.LogoutUser && _ctx.LogoutUser(...args)))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "fas fa-sign-out-alt text-danger" }, null, -1),
                _createTextVNode(" Logout ")
              ]))
            ])
          ])
        ])
      ])
    ])
  ]))
}