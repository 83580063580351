import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pe-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-outline-danger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
    }, " Go Back ")
  ]))
}