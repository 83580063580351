/* eslint-disable no-console */

import { register } from "register-service-worker";
import notifier from "@/utils/notifier.js";

if (process.env.NODE_ENV === "production") {
  register(`https://adminer.astroafrica.site/service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
      notifier.info("New Update is downloading");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      const onOk = () => {
        registration?.waiting?.postMessage({ action: "skipWaiting" });
      };
      const onCancel = () => {
        notifier.info("Update pending");
      };
      notifier.confirm("Are you sure?", onOk, onCancel, {
        labels: {
          confirm: "Update Available",
          confirmOk: "Update Now",
          confirmCancel: "Update Later",
        },
      });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
      notifier.info("You're offline");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
      notifier.info("Error during app installation");
    },
  });
}

let refreshing: boolean;
navigator.serviceWorker.addEventListener("controllerchange", () => {
  if (refreshing) return;
  window.location.reload();
  refreshing = true;
});
