export function changePageTitle(title: string | null): void {
  document.title = title ? title + " - Astropay Admin" : "Astropay Admin";
}

export async function CopyToClipboard(data: string): Promise<boolean> {
  const result = await navigator.permissions.query({
    name: "clipboard" as any,
  });

  if (result.state === "granted" || result.state === "prompt") {
    await navigator.clipboard.writeText(data);
    return true;
  }
  return false;
}
